import React from 'react';
import DashBox from './dash-box.svg';
import classNames from 'classnames';
import * as styles from './BoxList.module.scss';

export default function BoxList({
  className,
  listClassName,
  items,
  children,
  ...restProps
}) {
  return (
    <div
      className={classNames(styles.wrapper, className)}
      {...restProps}
    >
      <ul className={classNames(styles.list, listClassName)}>
        {items?.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
        {children}
      </ul>
      <DashBox className={styles.outline} />
    </div>
  );
}
