import React from 'react';
import Layout from '@layout';
import Hero from './Hero';
import Highlights from './Highlights';
import Experience from './Experience';
import Services from './Services';
import Clients from './Clients';

export default function Home() {
  return (
    <Layout>
      <Hero />
      <Highlights />
      <Experience />
      <Services />
      <Clients />
    </Layout>
  );
}
