import React from 'react';
import { Section, Content } from '@layout';
import * as Icon from './icons';
import { Link } from 'gatsby';
import * as styles from './Highlights.module.scss';

export default function Highlights() {
  return (
    <Section className={styles.wrapper}>
      <Content className={styles.content}>
        <div className={styles.item}>
          <div className={styles.icon}>
            <Icon.Planning />
          </div>
          <strong>Planning &amp; Conceptualization</strong>
          <p>
            Our projects often start at the early planning stages, putting
            together graphics for comprehensive plan amendments, rezoning
            and specific plans. These documents require a lot of thought
            because the commitments made in these documents become the law.
            Our experience in early planning makes sure that our clients
            plan a workable and aesthetic project.
          </p>
          <Link to="/services#landscape-plans">Learn more</Link>
        </div>

        <div className={styles.item}>
          <div className={styles.icon}>
            <Icon.Art />
          </div>
          <strong>Landscape Architecture</strong>
          <p>
            With thousands of successful projects, GRS has the experience
            to produce a creative, clear, easy to read set of documents
            that result in a beautiful project. Our landscape plans utilize
            the latest desert adapted species that grow well in our climate
            and need minimum maintenance. Our irrigation plans are water
            efficient, utilizing the latest, state-of-the-art equipment.
          </p>
          <Link to="/services">Learn more</Link>
        </div>

        <div className={styles.item}>
          <div className={styles.icon}>
            <Icon.Flower />
          </div>
          <strong>Environment Compliance</strong>
          <p>
            GRS has a large amount of experience preparing environmental
            documents to keep your project in compliance with a multitude
            of local, county, state and federal regulations. By
            coordinating all the documents like Riparian Habitat Mitigation
            Plans, Native Plant Preservation and Conservation Lands Systems
            we avoid double compliance that drives up the cost of your
            project.
          </p>
          <Link to="/services#native-plant-preservation-plans">
            Learn more
          </Link>
        </div>
      </Content>
    </Section>
  );
}
