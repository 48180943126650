import React from 'react';
import { DotSection, Content } from '@layout';
import { H2 } from '@headline';
import Button from '@button';
import BoxList from '@components/BoxList';
import * as styles from './Services.module.scss';

export default function Services() {
  return (
    <DotSection className={styles.wrapper}>
      <Content className={styles.content}>
        <div className={styles.text}>
          <H2 pretitle="Our Services">
            Full service landscape architecture
          </H2>
          <p>
            From Rezoning support, through environmental entitlements to
            landscape and irrigation plans, we are experienced in all
            aspects of landscape architectural services.
          </p>
          <Button
            to="/services"
            className={styles.button}
          >
            View all our services
          </Button>
        </div>
        <BoxList className={styles.list}>
          <li>Riparian Habitat Mitigation Plans</li>
          <li>Native Plant Preservation Plans (NPPO)</li>
          <li>Native Plant Inventories</li>
          <li>Landscape Plans</li>
          <li>Irrigation Plans</li>
          <li>Water Harvesting Plans</li>
          <li>Endangered Species Searches</li>
          <li>Rezoning Support</li>
        </BoxList>
        <Button
          to="/services"
          className={styles.mobileButton}
        >
          View all our services
        </Button>
      </Content>
    </DotSection>
  );
}
