import React from 'react';
import { Section, Content } from '@layout';
import { H2 } from '@headline';
import * as styles from './Experience.module.scss';

export default function Experience() {
  return (
    <Section
      className={styles.wrapper}
      theme="dark"
    >
      <Content className={styles.content}>
        <H2 pretitle="Why Choose GRS Landcape Architects?">
          <span>Over 30 years of experience</span>
        </H2>
        <p>
          Proudly serving clients throughout Tucson and the greater
          Southern Arizona area
        </p>
      </Content>
    </Section>
  );
}
