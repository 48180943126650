import React, { useState, useRef, useEffect } from 'react';
import { Content } from '@layout';
import { H1 } from '@headline';
import Corner from '@components/Corner';
import { Button, ButtonOutline, ButtonsWrapper } from '@button';
import useWindowSize from '@hooks/useWindowSize';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from './Hero.module.scss';
import classNames from 'classnames';

export default function Hero() {
  const imageWrapper = useRef(null);
  const [canAnimate, setCanAnimate] = useState(false);
  const [outlineIsLoaded, setOutlineIsLoaded] = useState(false);
  const [colorIsLoaded, setColorIsLoaded] = useState(false);
  const [partialIsLoaded, setPartialIsLoaded] = useState(false);
  const { width: windowWidth } = useWindowSize();

  useEffect(() => {
    const loadDelay = 100;
    const animationDelay = 2000;

    const loadTimer = setTimeout(() => {
      const wrapper = imageWrapper.current;

      if (wrapper?.querySelector(`.${styles.outline} img`)?.complete) {
        setOutlineIsLoaded(true);
      }

      if (wrapper?.querySelector(`.${styles.color} img`)?.complete) {
        setColorIsLoaded(true);
      }

      if (wrapper?.querySelector(`.${styles.partial} img`)?.complete) {
        setPartialIsLoaded(true);
      }
    }, loadDelay);

    const animateTimer = setTimeout(() => {
      setCanAnimate(true);
    }, animationDelay);

    return () => {
      clearTimeout(loadTimer);
      clearTimeout(animateTimer);
    };
  }, []);

  return (
    <Content
      tag="section"
      className={styles.wrapper}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <H1 pretitle="Professional Landscape architects">
            Designing spaces.
            <br />
            Building {windowWidth <= 615 ? 'community' : 'communities'}.
          </H1>
          <div className={styles.textWrapper}>
            <Corner
              side="topRight"
              dashLong="27"
              dashShort="7"
              dashGap="7"
              dashOffset="17"
              strokeWidth={3.2}
            />
            <div className={styles.text}>
              <p className="subtitle">
                GRS blends many years of experience with sound
                environmental policies, solid design and outstanding
                personal client service.
              </p>
              <p>
                Our experience includes a broad array of commercial,
                office, housing and environmental projects. These projects
                include small and large commercial, housing subdivisions,
                multifamily, industrial complexes, small office parks and
                planning efforts.
              </p>

              <ButtonsWrapper className={styles.buttonsWrapper}>
                <ButtonOutline to="/services">What we do</ButtonOutline>
                <Button to="/contact">Get in touch</Button>
              </ButtonsWrapper>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames(styles.graphic, {
          [styles.animate]:
            canAnimate &&
            outlineIsLoaded &&
            colorIsLoaded &&
            partialIsLoaded
        })}
        aria-hidden="true"
      >
        <div
          ref={imageWrapper}
          className={styles.imageWrapper}
        >
          <StaticImage
            src="./images/outline.png"
            alt=""
            layout="fixed"
            loading="eager"
            width={1145}
            className={classNames(styles.image, styles.outline)}
            placeholder="none"
            formats={['auto', 'webp', 'avif']}
            onLoad={() => setOutlineIsLoaded(true)}
          />

          <StaticImage
            src="./images/partial.png"
            alt=""
            layout="fixed"
            width={1145}
            className={classNames(styles.image, styles.partial)}
            placeholder="none"
            formats={['auto', 'webp', 'avif']}
            onLoad={() => setPartialIsLoaded(true)}
          />

          <StaticImage
            src="./images/color.jpg"
            alt=""
            layout="fixed"
            width={1145}
            className={classNames(styles.image, styles.color)}
            placeholder="none"
            formats={['auto', 'webp', 'avif']}
            onLoad={() => setColorIsLoaded(true)}
          />
        </div>
      </div>
    </Content>
  );
}
