import React, { useMemo, useRef, useEffect, useState } from 'react';
import { Section, Content } from '@layout';
import ClientSlider from './ClientSlider';
import useWindowSize from '@hooks/useWindowSize';
import * as styles from './Clients.module.scss';

export default function Clients() {
  const { width: windowWidth } = useWindowSize();

  const logoComponents = useMemo(() => {
    return logos.map(({ svg, image, alt, ...restProps }, index) => {
      const props = {
        key: index,
        ...restProps
      };

      return svg ? (
        <ClientLogo
          svg={svg}
          windowWidth={windowWidth}
          {...props}
        />
      ) : (
        <img
          src={image}
          alt={alt ?? ''}
          {...props}
        />
      );
    });
  }, [windowWidth]);

  return (
    <section className={styles.wrapper}>
      <Section
        tag="div"
        className={styles.top}
      >
        <Content>
          <p>GRS has helped hundreds of clients build and grow Arizona</p>

          {logoComponents.length && (
            <div className={styles.logosWrapper}>
              <div className={styles.logos}>{logoComponents}</div>
            </div>
          )}
        </Content>
      </Section>
      {/* <ClientSlider /> */}
    </section>
  );
}

function ClientLogo({
  svg: SVG,
  windowWidth,
  heightAdjustment = 18,
  ...restProps
}) {
  const ref = useRef(null);
  const baseHeight = useRef(null);
  const [height, setCurrentHeight] = useState(null);

  useEffect(() => {
    baseHeight.current =
      ref.current?.querySelector('svg')?.getAttribute('height') ?? null;
  }, []);

  useEffect(() => {
    if (windowWidth <= 1200 && baseHeight.current !== null) {
      setCurrentHeight(baseHeight.current - heightAdjustment);
    } else {
      setCurrentHeight(null);
    }
  }, [windowWidth, heightAdjustment]);

  return (
    <div ref={ref}>
      <SVG
        style={{ height }}
        {...restProps}
      />
    </div>
  );
}

const logos = [
  { svg: require('./logos/circle-k.svg') },
  { svg: require('./logos/kb-homes.svg') },
  { svg: require('./logos/starbucks.svg') },
  { svg: require('./logos/dunkin.svg'), heightAdjustment: 10 },
  { svg: require('./logos/meritage-homes.svg') }
];
